import { inject, observer } from "mobx-react";
import React from "react";
import { Button, Confirm, Icon, Image } from "semantic-ui-react";
import telefon from "../assets/Telefon.jpg";
import { OfferStore } from "../pages/offer/OfferStore";

interface IProps {
  OfferStore?: OfferStore;
}

class PhoneSwitch extends React.Component<IProps> {
  public render() {
    const { OfferStore } = this.props;

    return (
      <div>
        <div style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
          {
            (OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant || OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin) && OfferStore.offer.Id !== 0 &&
            <Button compact color='yellow' onClick={() => { OfferStore.cloneOffer() }}>
              Ajánlat klónozása
            </Button>

          }
          {
            (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.OnlinePickup) && OfferStore.offer.MainStatus === 1500 && OfferStore.showSuccessfulDocumentValidation &&
            <Button compact color='grey' style={{ marginLeft: 'auto' }} onClick={() => { OfferStore.setIsDocumentValidationSucceeded(true) }} >
              Dokumentum ellenőrzés megtörtént
            </Button>
          }
          {
                    (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.OnlinePickup) && OfferStore.offer.MainStatus == 1500 && (OfferStore.offer.AEGONStatus === 4300 || OfferStore.offer.AEGONStatus === 4200 || OfferStore.offer.AEGONStatus === 4150) && OfferStore.isLoanDemandable && !OfferStore.offer.IsMCCPressed &&
            <Button compact color='grey' style={{ marginLeft: 'auto' }} onClick={() => { OfferStore.CreateEbayTicket() }} >
              MCC dokumentumok létrehozása
            </Button>
          }
          {
                    (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.OnlinePickup) && (OfferStore.offer.MainStatus == 1500 || OfferStore.offer.MainStatus == 5000) && (OfferStore.offer.AEGONStatus === 4200 ||  OfferStore.offer.AEGONStatus === 4150 || OfferStore.offer.AEGONStatus === 4550 ) && OfferStore.isLoanDemandable && OfferStore.offer.IsMCCPressed &&
            <Button compact disabled color='green' style={{ marginLeft: 'auto' }} >
              MCC dokumentumok létrehozása
            </Button>
          }
          {
            (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.OnlinePickup) && OfferStore.offer.AEGONStatus === 4300 &&
            <Button compact color='grey' style={{ marginLeft: 'auto' }} onClick={() => { OfferStore.OfferBonding() }} >
              Kötvényesítés
            </Button>
          }
          {/*
            (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.OnlinePickup) && (OfferStore.offer.AEGONStatus === 4400 || OfferStore.offer.AEGONStatus === 4500) &&
            <Button compact disabled color='green' style={{ marginLeft: 'auto' }}>
              Kötvényesítés
            </Button>*/
          }

          {/* { (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.OnlinePickup) && OfferStore.offer.MainStatus === 1500 && !OfferStore.showSuccessfulDocumentValidation && OfferStore.offer.DocumentCheckDate &&
            <span style={{marginLeft: 'auto', paddingTop: 5}}>{`Sikeres dokumentum ellenőrzés időpontja: ${OfferStore.DocumentDateString}`}</span>
          }
          { (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.OnlinePickup) && OfferStore.offer.MainStatus === 1500 && !OfferStore.showSuccessfulDocumentValidation && !OfferStore.offer.DocumentCheckDate &&
            <span style={{marginLeft: 'auto', paddingTop: 5}}>{`Sikeres dokumentum ellenőrzés időpontja: ${OfferStore.DocumentValidationDate.toString()}`}</span>
          } */}
          {
            (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.OnlinePickup) && OfferStore.offer.MainStatus === 1500 && !OfferStore.showSuccessfulDocumentValidation &&
            <Button compact color='green' style={{ marginLeft: 'auto' }} onClick={() => { OfferStore.setIsDocumentValidationSucceeded(false) }} >
              Sikeres dokumentum ellenőrzés
            </Button>
          }
          {
            (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && OfferStore.offer.MainStatus < 5000 && OfferStore.offer.Id > 0 &&
            <Button compact color='orange' style={{ marginLeft: 'auto' }} onClick={() => {
              if (OfferStore.confirmSendBack == true) {
                OfferStore.SendOfferToStart();
              }
              else {
                OfferStore.openSendOfferToStartPopup()
              }


            }} >
              Ajánlat újraindítása 
            </Button>
          }
          <Confirm open={OfferStore.isSendOfferToStartPopupOpen}
            closeOnEscape={false} //disables closing the window by pressing ESC	
            closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
            onConfirm={(event) => {

              OfferStore.confirmSendBack = true;
              OfferStore.isSendOfferToStartPopupOpen = false;

            }}
            onCancel={(event) => { OfferStore.closeSendOfferToStartPopup(); }}
            cancelButton="Mégse"
            confirmButton="Tovább"
            content={
              <div style={{ padding: 30 }}>
                <b>Az „Ajánlat újraindítása” funkció CSAK ADDIG használható, ameddig a vásárló nem fogadta el és zárta le az összes nyilatkozatot!</b>
              </div>
            }
          ></Confirm>
        </div>
        <Button.Group fluid>
          {OfferStore.offer.Packages?.length < 6 && (
            <Button style={{ minHeight: '38px' }} compact icon="add" content={`Új csomag (${OfferStore.offer.Packages?.length}/6)`} disabled={!(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) || (OfferStore.offer.Packages?.length > 5 || OfferStore.offer.MainStatus > 1000)} onClick={() => {
              OfferStore.addPackageItem();
            }}></Button>
          )}
          {OfferStore.offer.Packages?.map((item, index) => {
            return <Button key={index} compact color={item.IsSelected ? "green" : null} style={{ maxWidth: OfferStore.offer.Packages?.length > 4 ? "235px" : "unset", padding: 0 }}
              onClick={() => {
                OfferStore.setSelectedItemActive(index)
              }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <div>
                  <Image src={telefon} verticalalign="bottom" style={{ maxHeight: "35px", maxWidth: "35px", marginRight: "15px" }} rounded spaced />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>{`${index + 1}. csomag`}</div>
                  {
                    ((OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && OfferStore.offer.MainStatus == 1000) &&

                    <div style={{ marginLeft: OfferStore.offer.Packages?.length > 4 ? "10px" : "10px" }}
                      onClick={(event) => {
                        OfferStore.removePackageItem(event, index);
                      }}>
                      <Icon circular inverted name="trash" fitted />
                    </div>
                  }
                </div>
              </div>
            </Button>
          })}
        </Button.Group>
      </div>

    );
  }
}

export default inject("OfferStore")(observer(PhoneSwitch));
