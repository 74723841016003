import { makeAutoObservable } from "mobx";
import { CommentsOfOffer } from "./commentsOfOffer";
import { OfferPackage } from "./offerPackage";

export class Offer {
    Id: number;
    OfferNumber: string;
    CreatedBy: number;
    Created: Date;
    ModifiedBy: number;
    Modified: Date;
    CustomerTitle: number;
    FirstName: string;
    LastName: string;
    BirthFirstName: string;
    BirthLastName: string;
    GenderForVodafone: number;
    GenderForVodafoneString: string;
    Gender: number;
    GenderString: string;
    BirthPlace: string;
    BirthDate: Date;
    MothersFirstName: string;
    MothersLastName: string;
    CountryOfNationality: string;
    IdentityNumber: string;
    IdentityType: number;
    PermanentAddressZipCode: number;
    PermanentAddressCity: string;
    PermanentAddressName: string;
    PermanentAddressType: string;
    PermanentAddressHouseNumber: string;
    PermanentAddressBuilding: string;
    PermanentAddressStaircase: string;
    PermanentAddressStorey: number;
    PermanentAddressDoor: string;
    TemporaryAddressZipCode: number;
    TemporaryAddressCity: string;
    TemporaryAddressName: string;
    TemporaryAddressType: string;
    TemporaryAddressHouseNumber: string;
    TemporaryAddressBuilding: string;
    TemporaryAddressStaircase: string;
    TemporaryAddressStorey: number;
    TemporaryAddressDoor: string;
    HRSZ: string;
    TemporaryHRSZ: string;
    MobileNumberPrefix: number;
    MobileNumber: string;
    Email: string;
    SIMNumber: string;
    IMEINumber: string;
    IsHRSZ: number;
    IsTemporaryHRSZ: number;
    Comments: Array<CommentsOfOffer>;
    Packages: Array<OfferPackage>;
    IsTemporaryAddressSameAsPermanent: boolean;
    Comment: string;
    MainStatus: number;
    VodafoneStatus: number;
    BPBankStatus: number;
    AEGONStatus: number;
    CreatedString: string;
    CreatedStringHomePage: string;
    PayableAmount: number;
    DownPayment: number;
    PayableAmountMonthly: number;
    IsLoanDemand: boolean;
    ContractAmount: number;
    Instalment: number;
    MainWfSteps: Array<any>;
    VodafoneWfSteps: Array<any>;
    BPBankWfSteps: Array<any>;
    AegonWfSteps: Array<any>;
    MainStatusString: string;
    VodafoneStatusString: string;
    BPBankStatusString: string;
    AEGONStatusString: string;
    MainDecision: any;
    VodafoneDecision: any;
    BpBankDecision: any;
    EditMode: boolean;
    VodafoneEditMode: boolean;
    BPBankEdigMode: boolean;
    StoreSAPCode: string;
    StoreId: number;
    IsGDPRAccepted: boolean;
    ClientConsent: boolean;
    CustomerName: string;
    OnlinePickupEditMode: boolean;
    DocumentCheckDate: Date;
    AddressCardNumber: string;
    IsMCCPressed: boolean;
    IsPermaAdddressNameChanged: boolean;
    IsTemporaryAdddressNameChanged: boolean;
    SubscriberContractNumber: string;
    IsSeniorConsultantPopupRead: boolean;
    NeedVodafoneCustomerSecurityCode: number;
    VodafoneCustomerSecurityCode: number;
    MKBTransactionNumber: string
    InformationObligationMM: string;
    InformationObligationId: number;
    IsInformationObligationRead: boolean;
    InformationObligationReadDate: Date;
    InformationObligationReadUserId: number;
    HasGPlus:boolean;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.OfferNumber = null;
        this.CreatedBy = 0;
        this.Created = null;
        this.ModifiedBy = 0;
        this.Modified = null;
        this.CustomerTitle = 0;
        this.FirstName = null;
        this.LastName = null;
        this.BirthFirstName = null;
        this.BirthLastName = null;
        this.GenderForVodafone = 0;
        this.GenderForVodafoneString = null;
        this.Gender = 0;
        this.GenderString = null;
        this.BirthPlace = null;
        this.BirthDate = null;
        this.MothersFirstName = null;
        this.MothersLastName = null;
        this.CountryOfNationality = null;
        this.IdentityNumber = null;
        this.IdentityType = 0;
        this.PermanentAddressZipCode = null;
        this.PermanentAddressCity = null;
        this.PermanentAddressName = null;
        this.PermanentAddressType = null;
        this.PermanentAddressHouseNumber = null;
        this.PermanentAddressBuilding = null;
        this.PermanentAddressStaircase = null;
        this.PermanentAddressStorey = null;
        this.PermanentAddressDoor = null;
        this.TemporaryAddressZipCode = null;
        this.TemporaryAddressCity = null;
        this.TemporaryAddressName = null;
        this.TemporaryAddressType = null;
        this.TemporaryAddressHouseNumber = null;
        this.TemporaryAddressBuilding = null;
        this.TemporaryAddressStaircase = null;
        this.TemporaryAddressStorey = null;
        this.TemporaryAddressDoor = null;
        this.HRSZ = null;
        this.TemporaryHRSZ = null;
        this.MobileNumberPrefix = 0;
        this.MobileNumber = null;
        this.Email = null;
        this.SIMNumber = null;
        this.IMEINumber = null;
        this.IsHRSZ = 0;
        this.IsTemporaryHRSZ = 0;
        this.Comments = [];
        this.Packages = [];
        this.IsTemporaryAddressSameAsPermanent = null;
        this.Comment = "";
        this.MainStatus = 0;
        this.VodafoneStatus = 0;
        this.BPBankStatus = 0;
        this.AEGONStatus = 0;
        this.CreatedString = null;
        this.CreatedStringHomePage = null;
        this.PayableAmount = 0;
        this.PayableAmountMonthly = 0;
        this.IsLoanDemand = false;
        this.DownPayment = 0;
        this.ContractAmount = 0;
        this.Instalment = 0;
        this.EditMode = false;
        this.VodafoneEditMode = false;
        this.BPBankEdigMode = false;
        this.StoreId = null;
        this.StoreSAPCode = null;
        this.IsGDPRAccepted = null;
        this.CustomerName = null;
        this.OnlinePickupEditMode = false;
        this.DocumentCheckDate = null;
        this.AddressCardNumber = null;
        this.IsMCCPressed = null;
        this.IsPermaAdddressNameChanged = false;
        this.IsTemporaryAdddressNameChanged = false;
        this.SubscriberContractNumber = null;
        this.IsSeniorConsultantPopupRead = false;
        this.NeedVodafoneCustomerSecurityCode = 0;
        this.VodafoneCustomerSecurityCode = null;
        this.MKBTransactionNumber = null;
        this.ClientConsent = null;
        this.InformationObligationMM = null;
        this.InformationObligationId = 0;
        this.IsInformationObligationRead = null;
        this.InformationObligationReadDate = null;
        this.InformationObligationReadUserId = null;
        this.HasGPlus=null;
    }
}